<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="12" sm="9" :class="$vuetify.breakpoint.xsOnly && 'mb-2'">
        <div class="d-flex align-center flex-wrap">
          <v-select
            v-model="initType"
            :items="filterType"
            item-value="type"
            item-text="label"
            :class="
              !$vuetify.breakpoint.smAndDown
                ? 'mr-2 select-150 d-inline-block'
                : 'mr-2 mb-3'
            "
            hide-details
            outlined
            dense
            @change="filterStatus()"
            style="max-width: 250px"
          />
          <div class="subtitle-2 font-weight-bold">{{ total }} {{ label }}</div>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          :label="$t('app.search')"
          style="max-width: 250px"
          outlined
          dense
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="handleSearch()"
        />
      </v-col>
    </v-row>

    <v-divider class="my-3" />

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      :loading="loading"
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
              small
              class="mx-2 primary"
              @click="showDialog(item)"
            >
              <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("personalization.edit_uid") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <Pagination
      :length="length"
      :total="total"
      :current-page="queryData.page"
      :limit="queryData.limit"
      :handler="paginateChange"
    />
    <Dialog
      :model="modelDialog"
      :isStudent="false"
      :listData="dataTable"
      :currentIndex="selectedIndex"
      @isOpen="isDialogClass"
      @getData="getData"
    />
  </div>
</template>

<script>
import { class_list } from "@/api/admin/academic/classList";
import { getEmployee } from "@/api/admin/master/employee";
import i18n from "@/i18n";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    init: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    Dialog: () => import("./Dialog")
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      dataTable: [],
      loading: false,
      tableHeaders: [
        {
          text: i18n.t("personalization.table.name"),
          align: "left",
          value: "name",
          width: 180
        },
        { text: i18n.t("app.nip"), value: "nip", width: 150 },
        { text: i18n.t("personalization.table.uid"), value: "uid", width: 150 },
        {
          text: i18n.t("personalization.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataAllList: {
        sort: "class_master",
        order: "DESC",
        free: "",
        search: "",
        filter: {}
      },
      dataClassList: [],
      employeeType: this.label.toUpperCase.toUpperCase,
      total: 0,
      length: 0,
      queryData: {
        page: 1,
        limit: 10,
        sort: ["person"],
        order: "DESC",
        search: "",
        institution: "",
        filter: this.filter
      },
      modelDialog: false,
      filterType: [
        { type: "ALL", label: i18n.t("app.all").toUpperCase() },
        {
          type: "GURUTETAP",
          label: i18n.t("master_data.permanent_teacher").toUpperCase()
        },
        {
          type: "HONORGTT",
          label: i18n.t("master_data.gtt_honor").toUpperCase()
        },
        {
          type: "HONORKOMITE",
          label: i18n.t("master_data.committee_honor").toUpperCase()
        },
        {
          type: "GURUTETAPYAYASAN",
          label: i18n
            .t("master_data.foundation_permanent_teacher")
            .toUpperCase()
        }
      ],
      initType: "ALL",
      allStatus: [],
      selectedIndex: 0,
      search: ""
    };
  },
  watch: {
    init: {
      immediate: true,
      handler() {
        this.getData();
      }
    },
    "queryData.page": {
      handler() {
        this.getData();
      },
      deep: true
    },
    "queryData.limit": {
      handler() {
        this.getData();
      },
      deep: true
    },
    search(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.search = value;
        this.getData();
      }, doneTypingInterval);
    }
  },
  async created() {
    this.dataClassList = await class_list(this.dataAllList).then(
      r => r.data.data.data
    );
  },
  methods: {
    handleSearch() {
      // check if UID searching
      if (Number(this.search).toString().length === 10) {
        const hexa = Number(this.search)
          .toString(16)
          .match(/.{1,2}/g)
          .reverse()
          .join("")
          .toUpperCase();
        this.search = hexa;
        this.queryData.search = hexa;
      } else {
        this.queryData.search = this.search;
      }
      this.getData();
    },
    filterStatus() {
      this.queryData.filter["employees.status"] =
        this.initType == "Semua" ? this.allStatus : [this.initType];
      this.dataTable = [];
      this.getData();
    },
    changeClass(id) {
      this.queryData.filter["class_students.class"] = [id];
      this.getData();
    },
    concatString: item => item.grade + " / " + item.name,
    getData() {
      this.dataTable = [];
      this.loading = true;
      if (this.queryData.filter["employees.status"] == "ALL")
        this.queryData.filter["employees.status"] = [];

      getEmployee(this.queryData)
        .then(res => {
          const r = res.data.data;
          this.dataTable = r.data;
          this.total = r.total;
          this.length = r.last_page;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
    },
    isDialogClass() {
      this.modelDialog = !this.modelDialog;
    },
    showDialog(data) {
      this.selectedIndex = this.dataTable.indexOf(data);
      this.modelDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select__slot {
  .v-label {
    font-size: 14px;
  }
}
</style>
